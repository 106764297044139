exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-conditioning-js": () => import("./../../../src/pages/conditioning.js" /* webpackChunkName: "component---src-pages-conditioning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-js": () => import("./../../../src/pages/installation.js" /* webpackChunkName: "component---src-pages-installation-js" */),
  "component---src-pages-projecting-js": () => import("./../../../src/pages/projecting.js" /* webpackChunkName: "component---src-pages-projecting-js" */),
  "component---src-pages-ventilation-js": () => import("./../../../src/pages/ventilation.js" /* webpackChunkName: "component---src-pages-ventilation-js" */)
}

